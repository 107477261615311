<template>
  <v-menu
    ref="menuDate"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    :nudge-right="40"
    :disabled="dateOptions.dateDisabled"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="showProperDate"
        :label="lableName"
        :rules="getRules"
        :disabled="dateOptions.dateDisabled"
        :clearable="dateOptions.dateClearable"
        prepend-icon="mdi-calendar"
        v-bind="attrs"
        v-on="on"
        hide-details="auto"
        @click:clear="clearDate(name)"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="date"
      @input="menu = false"
      :locale="getBCPLangCode"
      first-day-of-week="1"
      @click:date="$refs.menuDate.save($event)"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import validation from "../../validation";
export default {
  props: ["name", "lableName", "dateOptions", "dateValue", "dateIsFilter"],
  data() {
    return {
      menu: false,
      date: !this.dateIsFilter ? this.dateValue : "",
    };
  },
  created() {
    if (this.dateOptions.dateAutoSelectCurrent && !this.date) {
      this.date = new Date().toISOString().split("T")[0];
    }
  },
  computed: {
    getRules() {
      if (!this.dateOptions.dataFieldValidation) {
        return [];
      }
      return validation.getValidation(this.dateOptions.dateRules);
    },
    currentLanguage: {
      get: function () {
        return this.$i18n.locale();
      },
    },
    getBCPLangCode() {
      return this.$helpers.getBCPLangCode(this.currentLanguage);
    },
    showProperDate: {
      get() {
        if (this.date) {
          return this.$helpers.changeTimeToReadeble(this.date, false);
        }
        return null;
      },
      set(newVal) {
        this.date = newVal;
      },
    },
  },
  watch: {
    date: function () {
      this.$emit("changeDatePicker", this.date);
    },
  },
  methods: {
    clearDate() {
      this.date = null;
      this.$emit("clearDate");
    },
  },
};
</script>

<style>
</style>